export enum ValueAddedTaxTypeEnum {
    IngenMvaBehandlingInntekter = "21321311-0000-0000-0006-000000000007",
    FradragForInnforselsmvaRedusertSatsNaringsmidler = "21321311-0000-0000-0006-000000000015",
    GrunnlagVedInnforselAvVarerAlminneligSats = "21321311-0000-0000-0006-000000000021",
    GrunnlagVedInnforselAvVarerRedusertSatsNaringsmidler = "21321311-0000-0000-0006-000000000022",
    UtgaaendeMvaRedusertSats = "21321311-0000-0000-0006-000000000033",
    InnlandsOmsetningMedOmventAvgiftspliktZeroSats = "21321311-0000-0000-0006-000000000051",
    UtforselAvVarerOgTjenesterZeroSats = "21321311-0000-0000-0006-000000000052",
    InnforselAvVarerMedFradragForInnforselsMvaAlminneligSats = "21321311-0000-0000-0006-000000000081",
    InnforselAvVarerUtenFradragForInnforselsMvaAlminneligSats = "21321311-0000-0000-0006-000000000082",
    InnforselAvVarerMedFradragForInnforselsMvaRedusertSatsNaringsmidler = "21321311-0000-0000-0006-000000000083",
    InnforselAvVarerUtenFradragForInnforselsMvaRedusertSatsNaringsmidler = "21321311-0000-0000-0006-000000000084",
    InnforselAvVarerUtenMvaBeregningZeroSats = "21321311-0000-0000-0006-000000000085",
    InnforselAvTjenesterMedFradagForMvaAlminneligSats = "21321311-0000-0000-0006-000000000086",
    InnforselAvTjenesterUtenFradagForMvaAlminneligSats = "21321311-0000-0000-0006-000000000087",
    InnforselAvTjenesterMedFradagForMvaRedusertSats = "21321311-0000-0000-0006-000000000088",
    InnforselAvTjenesterUtenFradagForMvaRedusertSats = "21321311-0000-0000-0006-000000000089",
    KjopAvKlimakvoterEllerGullMedFradragForMvaAlminneligSats = "21321311-0000-0000-0006-000000000091",
    KjopAvKlimakvoterEllerGullUtenFradragForMvaAlminneligSats = "21321311-0000-0000-0006-000000000092",
    UtgaaendeMvaAlminneligSats = "7ad73727-98f1-4d61-94b6-018d2575bfad",
    UtgaaendeMvaRedusertSatsRaafisk = "a10a3863-d6a9-489a-80c0-1a83a50a6dfe",
    FradragForInngaaendeMvaRedusertSats = "d6b84c83-2802-40ea-8de7-1e5fe00cf779",
    InngaaendeAvgiftVedInnforselMiddelsSats = "a7b1389d-e7e7-4aaf-be59-2ee9a923ad54",
    BegrensetFradragInngMvaAlmSats = "f4384bc0-5b5a-429e-a456-4c2dde2e922f",
    MvaFrittSalgZeroSats = "423eb80c-8681-4570-bcb8-55a3cdfabf92",
    IngenMvaBehandlingAnskaffelser = "95938f10-ecf8-48a7-9fe4-55e5e4d03a11",
    OmsetningUtenforMvaLoven = "2f00ff4b-90e3-4732-8572-6014921a7d19",
    FradragForInngaaendeMvaAlminneligSats = "ae90d4c8-2beb-4cd0-aadb-8320ab10f25f",
    UtgaaendeAvgiftKjopAvTjenesterFraUtlandetMedFradragFullSats = "c8061a93-1818-41a6-9258-916d258956dd",
    FradragForInngaaendeMvaRedusertSatsRaafisk = "a8330d8f-c028-448f-a11c-98362ac51559",
    UtgaaendeAvgiftKjopAvTjenesterFraUtlandetUtenFradragFullSats = "e88a342f-50e2-4024-b184-b8b77d467a0f",
    GrunnlagVedInnforselAvVarerZeroSats = "16fdbf89-28d0-47a7-9516-c3ac1fa17c9c",
    BegrensetFradragInngMvaAlmSatsTwo = "2e353b89-99f7-411e-8d90-cbb659dc8bc6",
    FradragForInngaaendeMvaRedusertSatsNaringsmidler = "3cac337a-6e86-4158-9da9-df14f251a02c",
    BegrensetFradragInngMvaAlmSatsThree = "4c876472-c624-4ca4-a617-e8de5d11ef1c",
    UtgaaendeMvaRedusertSatsNaringsmidler = "7216fedd-7338-4938-8315-f2a08d13ef44",
    FradragForInnforselsmvaAlminneligSats = "917ddbfb-15bf-4919-ad3a-fc36acf4788a"
}
