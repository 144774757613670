<div class="button-row">
    <div class="button-cluster">
        <app-button-row-button [enabled]="canEdit && !showEdit" [label]="showOptionsPanel ? 'Lukk' : 'Søk'" [icon]="showOptionsPanel ? 'close' : 'search'" (click)="showOptionsPanel = !showOptionsPanel;" />
        <app-button-row-button [enabled]="canEdit" label="Ny" icon="add" (click)="resetSelectedType(); showEdit = true;" />
        <app-button-row-button [enabled]="selectedType.Id !== '' && canEdit" label="Endre" icon="edit" (click)="showEdit = true;" />
        <app-button-row-button [enabled]="showEdit" label="Avbryt" icon="cancel" (click)="showEdit = false;" />
        <app-button-row-button [enabled]="selectedType.Id !== ''"
            [label]="selectedType.Id !== '' ? selectedType.Active ? 'Deaktiver' : 'Aktiver' : 'Deaktiver'"
            [icon]="selectedType.Id !== '' ? selectedType.Active ? 'do_not_disturb_on' : 'task_alt' : 'do_not_disturb_on'"
            (click)="toggleActiveStatus()" />
    </div>
    <div class="button-cluster">
        <app-button-row-button label="Excel" icon="table_view" (click)="exportToExcel()" />
    </div>
</div>
@if(showOptionsPanel) {
    <div class="config-panel">
        <igx-input-group displayDensity="compact" type="border">
            <label igxLabel>Søketekst:</label>
            <input igxInput type="text" [(ngModel)]="optionsPanelState.seachText">
        </igx-input-group>
        <div class="simple-select-wrapper">
            <label #responsibleLabel igxLabel class="simple-select-label simple-select-label--selected">Hovedgruppe:</label>
            <igx-simple-combo [data]="mainGroups" displayDensity="compact" [type]="'border'"
                (opening)="combo.comboOpening(responsibleLabel)" (selectionChanging)="onGroupChange($event)"
                (closing)="combo.comboClosing(responsibleLabel, optionsPanelState.mainGroupId)" [displayKey]="'Name'" [valueKey]="'Id'"
                [(ngModel)]="optionsPanelState.mainGroupId">
            </igx-simple-combo>
        </div>
        <igx-checkbox [checked]="optionsPanelState.activeOnly">Kun aktive</igx-checkbox>
        <button igxButton="raised" igxButtonColor="white" igxRipple="white" (click)="onSearch()">Søk</button>
    </div>
}

@if(showEdit) {
    <div class="edit-panel">
        <h2>{{selectedType.Id !== '' ? 'Endre timetype' : 'Ny timetype'}}</h2>
        <div class="simple-select-wrapper">
            <label #mainGroupLabel igxLabel class="simple-select-label simple-select-label--selected">Hovedgruppe:</label>
            <igx-simple-combo #maingroups_new [data]="mainGroups" displayDensity="compact" [type]="'border'"
                (opening)="combo.comboOpening(mainGroupLabel)" (selectionChanging)="onGroupChange($event)"
                (closing)="combo.comboClosing(mainGroupLabel, selectedType.HourRegistrationMaingroupId)" [displayKey]="'Name'" [valueKey]="'Id'"
                [(ngModel)]="selectedType.HourRegistrationMaingroupId">
            </igx-simple-combo>
        </div>
        <igx-input-group displayDensity="compact" type="border">
            <label igxLabel>Kortform:</label>
            <input igxInput type="text" [(ngModel)]="selectedType.Code">
        </igx-input-group>
        <igx-input-group displayDensity="compact" type="border">
            <label igxLabel>Navn:</label>
            <input igxInput type="text" [(ngModel)]="selectedType.Name">
        </igx-input-group>
        <div class="simple-select-wrapper">
            <label #langLabel igxLabel class="simple-select-label simple-select-label--selected">Beskrivelsespråk:</label>
            <igx-simple-combo [data]="newTypeState.description.source" displayDensity="compact" [type]="'border'"
                (opening)="combo.comboOpening(langLabel)" (selectionChanging)="onGroupChange($event)"
                (closing)="combo.comboClosing(langLabel, newTypeState.description.default)" displayKey="name" valueKey="lang"
                [(ngModel)]="newTypeState.description.default">
            </igx-simple-combo>
        </div>
        <igx-input-group displayDensity="compact" type="border">
            <label igxLabel>Beskrivelse:</label>
            <input igxInput type="text" 
            [ngModel]="newTypeState.description.default === 'NO' ? newTypeState.description.no_desc: newTypeState.description.en_desc" 
            (ngModelChange)="newTypeState.description.default === 'NO' ? newTypeState.description.no_desc = $event : newTypeState.description.en_desc = $event">
        </igx-input-group>
        <div class="flex-row flex-gap-18">
            <igx-input-group displayDensity="compact" type="border">
                <label igxLabel>Timesats:</label>
                <input igxInput type="number" [(ngModel)]="newTypeState.rate">
            </igx-input-group>
            <div class="variable-check">
                <igx-checkbox [checked]="newTypeState.variableRate" labelPosition="after">Variabel sats:</igx-checkbox>
                <span>Honorar = timesats + timeforbruk</span>
            </div>
        </div>

        <div class="simple-select-wrapper">
            <label #invoiceTypeLabel igxLabel class="simple-select-label simple-select-label--selected">Fakturakode:</label>
            <igx-simple-combo [data]="newTypeState.invoiceCode.source" displayDensity="compact" [type]="'border'"
                (opening)="combo.comboOpening(invoiceTypeLabel)" (selectionChanging)="onGroupChange($event)"
                (closing)="combo.comboClosing(invoiceTypeLabel, newTypeState.invoiceCode.default)" displayKey="Name" valueKey="Id"
                [(ngModel)]="newTypeState.invoiceCode.default">
            </igx-simple-combo>
        </div>

        <strong>MVA innstillinger</strong>
        
    </div>
}

<igx-grid #grid [data]="filteredTypes" [rowSelection]="'single'" displayDensity="compact"
    (rowSelectionChanging)="onRowSelection($event)" hideRowSelectors="true" [allowFiltering]="true">
    <igx-column width="140px" field="Code" header="Kortform" [sortable]="true" dataType="string" [filterCellTemplate]="defaultFilterTemplate" />
    <igx-column field="Name" header="Navn" [sortable]="true" dataType="string" [filterCellTemplate]="defaultFilterTemplate" />
    <igx-column width="140px" field="Rate" header="Sats" [sortable]="true" dataType="number" [filterCellTemplate]="defaultFilterTemplate">
        <ng-template igxCell let-cell="cell">
            <span>{{cell.value | norwegianNumberFormat}}</span>
        </ng-template>
    </igx-column>
    <igx-column width="45px" field="InvoiceTypeCode" header="F" [sortable]="true" dataType="string" [filterCellTemplate]="defaultFilterTemplate" />
    <igx-column width="80px" field="HourRegistrationMaingroupCode" header="Gruppe" [sortable]="true" dataType="string" [filterCellTemplate]="defaultFilterTemplate" />
    <igx-column width="80px" field="Active" header="Aktiv" [sortable]="true" dataType="boolean" [filterCellTemplate]="defaultFilterTemplate" />
</igx-grid>

<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
    <grid-filter-input [grid]="grid" [column]="column"></grid-filter-input>
  </ng-template>