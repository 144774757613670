import { CommonModule } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatalexClient, IHourCostTypeVatBE, IHourRegistrationMaingroupBE, IHourRegistrationTypeBE, IValueAddedTaxTypeBE, UserAreaEnum, UserRightTypeEnum } from '@datalex-software-as/datalex-client';
import { IgxButtonModule, IgxCheckboxModule, IgxColumnComponent, IgxExcelExporterOptions, IgxExcelExporterService, IgxGridComponent, IgxInputGroupModule, IgxSimpleComboComponent, IRowSelectionEventArgs, ISimpleComboSelectionChangingEventArgs } from '@infragistics/igniteui-angular';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { ButtonRowButtonComponent } from '../../../button-row-button/button-row-button.component';
import { NorwegianNumberFormatPipe } from "../../../../../util/pipes/norwegian-number-format.pipe";
import { GridFilterInputComponent } from "../../../grid-filter-input/grid-filter-input.component";
import { invoice } from '@igniteui/material-icons-extended';
import { InvoiceTypeCode } from 'src/app/util/InvoiceTypeEnum';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { ValueAddedTaxTypeEnum } from 'src/app/util/VATTypeEnum';
import { get } from 'http';

@Component({
  selector: 'dlx-hour-registration-type-editor',
  standalone: true,
  imports: [IgxSimpleComboComponent, FormsModule, IgxGridComponent, IgxColumnComponent, IgxInputGroupModule, IgxButtonModule, CommonModule, ButtonRowButtonComponent, IgxCheckboxModule, NorwegianNumberFormatPipe, GridFilterInputComponent],
  templateUrl: './hour-registration-type-editor.component.html',
  styleUrl: './hour-registration-type-editor.component.scss'
})
export class HourRegistrationTypeEditorComponent {
  dlxClient = inject(DatalexClient);
  userRights = inject(UserRightsProviderService);
  combo = inject(ComboSelectFunctionsService);
  sys = inject(SystemCacheService);
  excel = inject(IgxExcelExporterService)

  @ViewChild('grid', { static: true }) grid!: IgxGridComponent;

  /* Component state variables */
  mainGroups: IHourRegistrationMaingroupBE[] = [];
  types: IHourRegistrationTypeBE[] = [];
  filteredTypes: IHourRegistrationTypeBE[] = [];
  canEdit: boolean = false;
  showEdit: boolean = false;
  showOptionsPanel: boolean = false;

  optionsPanelState = {
    seachText: '',
    activeOnly: true,
    mainGroupId: ''
  }


  newType: IHourRegistrationTypeBE | null = null;
  vatItem: IValueAddedTaxTypeBE | null = null;


  vatTypes: IValueAddedTaxTypeBE[] = [];

  newTypeState = {
    mainGroupId: '',
    code: '',
    name: '',
    description: {
      source: [{
        lang: 'NO',
        name: 'Norsk'
      },
      {
        lang: 'EN',
        name: 'Engelsk'
      }],
      default: 'NO',
      no_desc: '',
      en_desc: ''
    },
    variableRate: true,
    rate: null,
    invoiceCode: {
      default: InvoiceTypeCode.J.toLowerCase(),
      source: this.sys.invoiceTypes
    },
    vatSettings: {
      account: {
        default: '',
        source: []
      },
      vat: {
        default: '',
        source: []
      },
      mainAccount: {},
      mainAccountNoTax: {},
    }

  }

  


  selectedType: IHourRegistrationTypeBE = getEmptyType();
  resetSelectedType(){
    this.selectedType = getEmptyType();
  }
  /* On new state variables */
  // newTypeState = {
  //   code: '',
  //   name: ''
  // }

  ngOnInit(): void {
    this.canEdit = this.userRights.checkAccess(UserAreaEnum.REGISTERS).accessId.toUpperCase() === UserRightTypeEnum.FULL;
    this.dlxClient.GetHourRegistrationMaingroups().subscribe({
      next: (maingroups) => {
        this.mainGroups = maingroups;
      }
    })
    this.dlxClient.GetHourRegistrationTypes().subscribe({
      next: (types) => {
        this.types = types;
        this.filteredTypes = this.types;
      }
    })

    console.log(this.newTypeState);

  }

  onGroupChange(evt: ISimpleComboSelectionChangingEventArgs) {
    console.log(evt);

    if (evt.newValue) {
      this.filteredTypes = this.types.filter((type) => type.HourRegistrationMaingroupId === evt.newValue);
    } else {
      this.filteredTypes = this.types;
    }
  }

  onRowSelection(e: IRowSelectionEventArgs) {
    const { Id } = e.newSelection[0] as IHourRegistrationTypeBE;
    console.log(this.selectedType);
    this.dlxClient.GetHourRegistrationType(Id).subscribe({
      next: (type) => {
        this.selectedType = type;
        console.log("selectedType:", this.selectedType)
      }
    })
    
    // this.dlxClient.GetAccountsLimiteds().subscribe({
    //   next: (accounts) => {
    //     console.log("accounts:", accounts)
    //   }
    // })
    this.dlxClient.GetValueAddedTaxTypes().subscribe({
      next: (vatTypes) => {
        const vat = this.filterValueAddedTaxTypes(vatTypes);
        this.vatTypes = vat;
        console.log("vat:", vat)
      }
    })
    // this.dlxClient.GetHourRegistrationTypeVatItem(this.selectedType.Id, this.sys.loggedInUserAccount.Id).subscribe({
    //   next: (vatItem) => {
    //     console.log('vatItems:',vatItem);
    //   }
    // })

  }

  filterValueAddedTaxTypes(list: IValueAddedTaxTypeBE[]): IValueAddedTaxTypeBE[] {
    return list.filter(r =>
      r.Id.toUpperCase() === ValueAddedTaxTypeEnum.MvaFrittSalgZeroSats.toUpperCase() ||            // MVA kode 5
      r.Id.toUpperCase() === ValueAddedTaxTypeEnum.UtforselAvVarerOgTjenesterZeroSats.toUpperCase() ||  // MVA kode 52
      r.Id.toUpperCase() === ValueAddedTaxTypeEnum.OmsetningUtenforMvaLoven.toUpperCase() ||        // MVA kode 6
      r.Id.toUpperCase() === ValueAddedTaxTypeEnum.UtgaaendeMvaAlminneligSats.toUpperCase() ||      // MVA kode 3
      r.Id.toUpperCase() === ValueAddedTaxTypeEnum.UtgaaendeMvaRedusertSatsNaringsmidler.toUpperCase() || // MVA kode 31
      r.Id.toUpperCase() === ValueAddedTaxTypeEnum.UtgaaendeMvaRedusertSatsRaafisk.toUpperCase() || // MVA kode 32
      r.Id.toUpperCase() === ValueAddedTaxTypeEnum.UtgaaendeMvaRedusertSats.toUpperCase()           // MVA kode 33
    );
  }


  onSearch() {
    if (this.optionsPanelState.seachText === '') {
      if (this.optionsPanelState.activeOnly) {
        this.filteredTypes = this.types.filter(type => type.Active === true);
      } else {
        this.filteredTypes = this.types;
      }
      return;
    }

    this.filteredTypes = this.types.filter(type =>
      type.Code.toLowerCase().includes(this.optionsPanelState.seachText.toLowerCase()) ||
      type.Name.toLowerCase().includes(this.optionsPanelState.seachText.toLowerCase())
    );

    if (this.optionsPanelState.activeOnly) {
      this.filteredTypes = this.filteredTypes.filter(type => type.Active === true);
    }
    if (this.optionsPanelState.mainGroupId) {
      this.filteredTypes = this.filteredTypes.filter(type => type.HourRegistrationMaingroupId === this.optionsPanelState.mainGroupId);
    }

  }

  onSave() {
    this.dlxClient.NewHourRegistrationType().subscribe({
      next: (result) => {
        result.Active = true;
        result.Code = this.newTypeState.code;
        result.Name = this.newTypeState.name;
        result.DeleteAllowed = true;
        result.IsNew = true;

        this.dlxClient.SaveHourRegistrationType(result).subscribe({
          next: (mainGroup) => {
            this.newTypeState.code = "";
            this.newTypeState.name = "";
            this.types.push(mainGroup);
            if (this.optionsPanelState.mainGroupId) {
              this.filteredTypes = this.types.filter((type) => type.HourRegistrationMaingroupId === this.optionsPanelState.mainGroupId);
            }
          }
        })
      }
    })
  }


  toggleActiveStatus() {

  }

  exportToExcel(){
    this.excel.export(this.grid, new IgxExcelExporterOptions('Typetyper'));
  }

}


function getEmptyHourCostTypeVat(): IHourCostTypeVatBE {
  return {
    Id: '',
    AccountsId: '',
    HourTypeId: null,
    CostTypeId: null,
    ValueAddedTaxTypeId: null,
    MasterAccountId: null,
    MasterTaxFreeAccountId: null,
    Timestamp: [],
    IsNew: true,
    IsDeleted: false
  }

}

function getEmptyType(): IHourRegistrationTypeBE {
  return { 
    Id: '',
    HourRegistrationMaingroupId: '',
    HourRegistrationMaingroupCode: '',
    Code: '',
    Name: '',
    Timestamp: [],
    Rate: null,
    IsRateVariable: true,
    DeleteAllowed: true,
    InvoiceTypeId: InvoiceTypeCode.J.toLowerCase(),
    InvoiceTypeCode: 'J',
    Active: true,
    IsNew: true,
    IsDeleted: false,
    HourRegistrationTypeDescriptions: [],
    HourTypeVatItem: getEmptyHourCostTypeVat(),
    DefaultMinuttes: null,
  }
}